<template>
  <div>
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    <el-dialog :title="titleName" :visible.sync="dialogFormVisible" width="500px" @close="close()">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="商品名称" label-width="170px" prop="name">
          <el-input v-model.trim="form.name" style="width: 200px;display: inline-block;" maxlength="10"
            placeholder="请输入" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="商品品类" label-width="170px" prop="type">
          <el-select style="width: 200px;display: inline-block;" filterable @change="typeChange(form.type)" :disabled="notEdit" v-model="form.type" placeholder="请选择商品品类"
            clearable>
            <el-option v-for="it in typeList" :key="it.value" :label="it.label" :value="it.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="typeShow" label="充电卡类型" label-width="170px" prop="itemType">
          <el-select style="width: 200px;display: inline-block;" filterable :disabled="notEdit" v-model="form.itemType" placeholder="请选择充电卡类型"
            clearable>
            <el-option v-for="it in itemTypeList" :key="it.value" :label="it.label" :value="it.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="售价/租金" label-width="170px" prop="price">
          <el-input v-model.trim="form.price" style="width: 200px;display: inline-block;" maxlength="10"
            placeholder="请输入" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="商品状态" label-width="170px" prop="state">
          <el-select style="width: 200px;display: inline-block;" filterable v-model="form.state" placeholder="请选择商品状态"
            clearable>
            <el-option v-for="it in stateList" :key="it.value" :label="it.label" :value="it.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="close()">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import cabinetUtil from "@utils/cabinetUtil.js"
  let priceVali = (rule, value, callback) => {
    var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,4}$)/
    if (!value) {
      callback('请输入售价/租金');
    }
    if (!reg.test(value)) {
      callback(
        new Error("请输入正数，且小数点后最多保留4位")
      );
    } else {
      callback();
    }
  };
  export default {
    data() {
      let _this = this
      return {
        titleName: '新增',
        dialogFormVisible: false,
        notEdit: false,
        itemId:"",
        typeShow: false,
        form: {
          name: '',
          type: '',
          itemType: '',
          price: '',
          state: '',
        },
        typeList: [{
            label: '充电卡',
            value: 1,
          },
          {
            label: '租电池押金',
            value: 2,
          }
        ],
        itemTypeList: [{
            label: '月卡',
            value: 1,
          },
          {
            label: '季卡',
            value: 2,
          },
          {
            label: '年卡',
            value: 3,
          }],
        stateList: [{
            label: '上架',
            value: 1,
          },
          {
            label: '下架',
            value: 2,
          }
        ],
        rules: {
          name: [{
            required: true,
            message: "请输入商品名称",
          }],
          type: [{
            required: true,
            message: "请选择商品品类",
          }],
          itemType: [{
            required: true,
            message: "请选择充电卡类型",
          }],
          price: [{
            required: true,
            trigger: "change",
            validator: priceVali,
          }],
          state: [{
            required: true,
            message: "请选择商品状态",
          }],
        },
        opt: {
          title: '商品管理',
          search: [
            {
                key: "merchantId",
                label: "商家名称",
                type: 'remoteSearchById',
                opt: {
                  list: [],
                }
            },
          ],
          columns: [{
              label: "商品id",
              key: "id"
            },
            {
              label: "商品名称",
              key: "name"
            },
            {
              label: "商家名称",
              key: "merchantName",
            },
            {
              label: "商品品类",
              key: "typeStr"
            },
            {
              label: "充电卡类型",
              key: "itemTypeStr"
            },
            {
              label: "售价/租金",
              key: "price"
            },
            {
              label: "状态",
              key: "stateStr"
            },
          ],
          buttons: [{
            type: 0,
            on() {
              _this.addFun();

            }
          }, {
            type: 1,
            on(row) {
              _this.editFun(row);

            }
          }]
        }
      }
    },

    async activated() {
      try {
        this.merchantFlag = this.getLoginType('loginType');
        if (this.merchantFlag) {
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              this.opt.search.splice(index, 1);
            }
          })
          this.opt.columns.forEach((item, index) => {
            if (item.key == 'merchantName') {
              this.opt.columns.splice(index, 1);
            }
          })
        } else {
          let merchantList = await cabinetUtil.getMerchantList();
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              item.opt.list = merchantList;
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    },

    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm,
        }
        this.post('mall-service/bc/item/page', dto, {
          isUseResponse: true,
        }).then(res => {
          res.data.data = res.data.list;
          res.data.list.forEach(item => {
            item.typeStr = ['充电卡','租电池押金'][item.type - 1]
            item.stateStr = ['上架','下架'][item.state - 1]
            item.itemTypeStr = item.type == 1? ['月卡','季卡','年卡'][item.itemType - 1] : '--'
            item.price = item.price ? Number(item.price).toFixed(2) : '--';
            item.merchantName = item.merchantName || '--';
          })
          opt.cb(res.data)
        })
      },
      addFun() {
        this.titleName = "新增";
        this.notEdit = false;
        this.dialogFormVisible = true;
        this.typeShow = false
        this.form = {
          name: "",
          type: "",
          itemType: "",
          price: "",
          state: "",
        }
        // 对表单进行重置
        this.$nextTick(() => {
          this.$refs["form"].resetFields();
        })
      },
      editFun(row) {
        this.titleName = '编辑';
        this.dialogFormVisible = true;
        this.itemId = row.id;
        this.notEdit = true;
        this.$nextTick(() => {
          this.form.name = row.data.name;
          this.form.type = row.data.type;
          this.form.price = row.data.price;
          this.form.state = row.data.state;
          if (row.data.type == 1 ) {
            this.typeShow = true
            this.form.itemType = row.data.itemType
          } else {
            this.typeShow = false
          }
        })
      },
      submit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let url;
            let message;
            let dto = {
              name: this.form.name,
              type: this.form.type,
              itemType: this.form.itemType,
              price: this.form.price,
              state: this.form.state
            }
            if(this.notEdit == false) {
              url = "mall-service/bc/item/save"
              message = '新增商品成功'
            }else if(this.notEdit == true) {
              url = "mall-service/bc/item/update"
              dto.id = this.itemId
              message = '编辑商品成功'
            }
            this.post(url,dto).then(res => {
              this.dialogFormVisible = false;
              this.$message({
                showClose: true,
                message: message,
                type: "success"
              })
              this.$refs.myList.get();
            })
          }
        })
      },
      typeChange(val) {
        if(val == 1){
          this.typeShow = true
        } else {
          this.typeShow = false
        }
      },
      close() {
        this.typeShow = false
        this.dialogFormVisible = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .dialog-footer {
    text-align: center;
  }
</style>