var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-list", {
        ref: "myList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titleName,
            visible: _vm.dialogFormVisible,
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              _vm.close()
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品名称",
                    "label-width": "170px",
                    prop: "name"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px", display: "inline-block" },
                    attrs: {
                      maxlength: "10",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品品类",
                    "label-width": "170px",
                    prop: "type"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px", display: "inline-block" },
                      attrs: {
                        filterable: "",
                        disabled: _vm.notEdit,
                        placeholder: "请选择商品品类",
                        clearable: ""
                      },
                      on: {
                        change: function($event) {
                          _vm.typeChange(_vm.form.type)
                        }
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    },
                    _vm._l(_vm.typeList, function(it) {
                      return _c("el-option", {
                        key: it.value,
                        attrs: { label: it.label, value: it.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.typeShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "充电卡类型",
                        "label-width": "170px",
                        prop: "itemType"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block"
                          },
                          attrs: {
                            filterable: "",
                            disabled: _vm.notEdit,
                            placeholder: "请选择充电卡类型",
                            clearable: ""
                          },
                          model: {
                            value: _vm.form.itemType,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "itemType", $$v)
                            },
                            expression: "form.itemType"
                          }
                        },
                        _vm._l(_vm.itemTypeList, function(it) {
                          return _c("el-option", {
                            key: it.value,
                            attrs: { label: it.label, value: it.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "售价/租金",
                    "label-width": "170px",
                    prop: "price"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px", display: "inline-block" },
                    attrs: {
                      maxlength: "10",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    model: {
                      value: _vm.form.price,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "price",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.price"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品状态",
                    "label-width": "170px",
                    prop: "state"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px", display: "inline-block" },
                      attrs: {
                        filterable: "",
                        placeholder: "请选择商品状态",
                        clearable: ""
                      },
                      model: {
                        value: _vm.form.state,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "state", $$v)
                        },
                        expression: "form.state"
                      }
                    },
                    _vm._l(_vm.stateList, function(it) {
                      return _c("el-option", {
                        key: it.value,
                        attrs: { label: it.label, value: it.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.close()
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.submit()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }